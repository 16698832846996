import Container from "../Container";
import Title from "../form/Title";
import Submit from "../form/Submit";
import { useEffect, useRef, useState } from "react";
import FormContainer from "../form/FormContainer";
import { commonModalClasses } from "../../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { resendEmailVerificationToken, verifyUserEmail } from "../../api/auth";
import { useAuth, useNotification } from "../../hooks";

const OTP_LENGTH = 6;
let currentOtpIndex;

const isValidOTP = (otp) => {
    let valid = false;

    for (let val of otp) {
        valid = !isNaN(parseInt(val));
        if (!valid) break;
    }

    return valid;
};

const EmailVerification = () => {
    const [otp, setOtp] = useState(new Array(OTP_LENGTH).fill(""));
    const [activeOtpIndex, setActiveOtpIndex] = useState(0);

    const { isAuth, authInfo } = useAuth();
    const { isLoggedIn, profile } = authInfo;
    const isVerified = profile?.isVerified;

    const inputRef = useRef();

    const { updateNotification } = useNotification();

    const { state } = useLocation();
    const user = state?.user;

    const navigate = useNavigate();

    const handleOtpChange = ({ target }) => {
        const { value } = target;

        const newOtp = [...otp];
        newOtp[currentOtpIndex] = value.substring(
            value.length - 1,
            value.length
        );

        if (!value) focusPrevInputField(currentOtpIndex);
        else focusNextInputField(currentOtpIndex);

        setOtp([...newOtp]);
    };

    const handleOTPResend = async () => {
        const { error, message } = await resendEmailVerificationToken(user.id);
        if (error) return updateNotification("error", error);

        updateNotification("success", message);
    };

    const focusNextInputField = (index) => {
        setActiveOtpIndex(index + 1);
    };

    const focusPrevInputField = (index) => {
        let nextIndex;
        const diff = index - 1;
        nextIndex = diff !== 0 ? diff : 0;

        setActiveOtpIndex(nextIndex);
    };

    const handleKeyDown = ({ key }, index) => {
        currentOtpIndex = index;
        if (key === "Backspace") {
            focusPrevInputField(currentOtpIndex);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isValidOTP(otp)) return updateNotification("error", "Invalid OTP");

        const {
            error,
            message,
            user: userResponse,
        } = await verifyUserEmail({
            userId: user.id,
            OTP: otp.join(""),
        });
        if (error) return updateNotification("error", error);

        updateNotification("success", message);

        localStorage.setItem("auth-token", userResponse.token);
        isAuth();
    };

    useEffect(() => {
        inputRef.current?.focus();
    }, [activeOtpIndex]);

    useEffect(() => {
        if (!user) navigate("/not-found");
        if (isLoggedIn && isVerified) navigate("/");
    }, [user, isLoggedIn, isVerified]);

    // if (!user) return null;

    return (
        <FormContainer>
            <Container>
                <form onSubmit={handleSubmit} className={commonModalClasses}>
                    <div>
                        <Title>
                            Please enter the OTP to verify your account.
                        </Title>
                        <p className="text-center text-light-subtle dark:text-dark-subtle">
                            OTP has been sent to your email.
                        </p>
                    </div>

                    <div className="flex justify-center items-center space-x-4">
                        {otp.map((_, index) => {
                            return (
                                <input
                                    key={index}
                                    ref={
                                        activeOtpIndex === index
                                            ? inputRef
                                            : null
                                    }
                                    type="number"
                                    value={otp[index] || ""}
                                    className="w-12 h-12 border-2 border-light-subtle dark:border-dark-subtle bg-transparent rounded focus:border-primary dark:focus:border-white outline-none text-center text-primary dark:text-white font-semibold text-xl spin-button-none"
                                    onChange={handleOtpChange}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                />
                            );
                        })}
                    </div>

                    <div>
                        <Submit value="Verify Account" />
                        <button
                            type="button"
                            className="dark:text-white text-blue-500 font-semibold hover:underline mt-2"
                            onClick={handleOTPResend}
                        >
                            Didn't Recieved OTP?
                        </button>
                    </div>
                </form>
            </Container>
        </FormContainer>
    );
};

export default EmailVerification;
