import { ImSpinner3 } from "react-icons/im";

const Submit = ({ value, busy, type, onClick }) => {
    return (
        <button
            type={type || "submit"}
            className="w-full rounded bg-secondary dark:bg-white hover:bg-opacity-90 transition font-semibold text-lg text-white dark:text-secondary cursor-pointer h-10 flex items-center justify-center"
            onClick={onClick}
        >
            {busy ? <ImSpinner3 className="animate-spin" /> : value}
        </button>
    );
};

export default Submit;
