import { useEffect, useState } from "react";
import { commonInputClasses } from "../../utils/theme";
import PosterSelector from "../PosterSelector";
import Selector from "../Selector";
import { useNotification } from "../../hooks";
import { ImSpinner3 } from "react-icons/im";

const defaultActorInfo = {
    name: "",
    about: "",
    avatar: null,
    gender: "",
};

const genderOptions = [
    { title: "Male", value: "male" },
    { title: "Female", value: "female" },
];

const validateActor = ({ avatar, name, about, gender }) => {
    if (!name.trim()) return { error: "Actor's name is missing!" };
    if (!about.trim()) return { error: "About section is missing!" };
    if (!gender.trim()) return { error: "Actor's gender is missing!" };
    if (avatar && !avatar.type?.startsWith("image"))
        return { error: "Invalid image/avatar file!" };

    return { error: null };
};

const ActorForm = ({ title, btnTitle, busy, onSubmit, initialState }) => {
    const [actorInfo, setActorInfo] = useState({ ...defaultActorInfo });
    const [selectedAvatarForUI, setSelectedAvatarForUI] = useState("");

    const { updateNotification } = useNotification();

    const updatePosterForUI = (file) => {
        const url = URL.createObjectURL(file);
        setSelectedAvatarForUI(url);
    };

    const handleChange = ({ target }) => {
        const { value, files, name } = target;

        if (name === "avatar") {
            const file = files[0];
            updatePosterForUI(file);
            return setActorInfo({ ...actorInfo, avatar: file });
        }

        setActorInfo({ ...actorInfo, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { error } = validateActor(actorInfo);
        if (error) return updateNotification("error", error);

        // submit form
        const formData = new FormData();

        for (let key in actorInfo) {
            if (key) formData.append(key, actorInfo[key]);
        }

        onSubmit(formData);
    };

    useEffect(() => {
        if (initialState) {
            setActorInfo({ ...initialState, avatar: null });
            setSelectedAvatarForUI(initialState.avatar);
        }
    }, [initialState]);

    const { name, about, gender } = actorInfo;

    return (
        <form
            onSubmit={handleSubmit}
            className="dark:bg-primary bg-white p-3 w-[35rem] rounded"
        >
            <div className="flex justify-between items-center mb-3">
                <h1 className="font-semibold text-xl dark:text-white text-primary">
                    {title}
                </h1>

                <button
                    className="h-8 w-24 bg-primary text-white dark:bg-white dark:text-primary hover:opacity-80 transition rounded flex items-center justify-center"
                    type="submit"
                >
                    {busy ? <ImSpinner3 className="animate-spin" /> : btnTitle}
                </button>
            </div>

            <div className="flex space-x-2">
                <PosterSelector
                    label="Select Avatar"
                    accept="image/jpg, image/jpeg, image/png"
                    selectedPoster={selectedAvatarForUI}
                    className="w-36 h-36 aspect-square object-cover rounded"
                    name="avatar"
                    onChange={handleChange}
                />

                <div className="flex-grow flex flex-col space-y-2">
                    <input
                        type="text"
                        placeholder="Enter name"
                        name="name"
                        value={name}
                        className={commonInputClasses + " border-b-2"}
                        onChange={handleChange}
                    />
                    <textarea
                        placeholder="About"
                        name="about"
                        value={about}
                        onChange={handleChange}
                        className={
                            commonInputClasses +
                            " h-full border-b-2 resize-none"
                        }
                    ></textarea>
                </div>
            </div>

            <div className="mt-3">
                <Selector
                    options={genderOptions}
                    label="Gender"
                    value={gender}
                    onChange={handleChange}
                    name="gender"
                />
            </div>
        </form>
    );
};

export default ActorForm;
