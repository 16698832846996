import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";

const defaultInputStyle =
    "dark:text-white dark:border-dark-subtle border-light-subtle focus:border-primary dark:focus:border-white text-lg";

const AppSearchForm = ({
    placeholder,
    inputClassName = defaultInputStyle,
    onSubmit,
    showResetIcon,
    onReset,
}) => {
    const [value, setValue] = useState("");

    const handleOnSubmit = (e) => {
        e.preventDefault();
        onSubmit(value);
    };

    const handleReset = () => {
        setValue("");
        onReset();
    };

    return (
        <form className="relative" onSubmit={handleOnSubmit}>
            <input
                type="text"
                placeholder={placeholder}
                className={
                    "border-2 transition bg-transparent rounded p-1 outline-none" +
                    inputClassName
                }
                value={value}
                onChange={({ target }) => setValue(target.value)}
            />

            {showResetIcon ? (
                <button
                    onClick={handleReset}
                    type="button"
                    className="absolute top-1/2 -translate-y-1/2 right-2 text-secondary dark:text-white"
                >
                    <AiOutlineClose />
                </button>
            ) : null}
        </form>
    );
};

export default AppSearchForm;
