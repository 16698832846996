export const validateMovie = (movieInfo) => {
    const {
        title,
        storyLine,
        language,
        releaseDate,
        status,
        type,
        genres,
        tags,
        cast,
    } = movieInfo;

    if (!title.trim()) return { error: "Title is missing!" };
    if (!storyLine || !storyLine.trim())
        return { error: "Story Line is missing!" };
    if (!language.trim()) return { error: "Language is missing!" };
    if (!releaseDate.trim()) return { error: "Release date is missing!" };
    if (!status.trim()) return { error: "Status is missing!" };
    if (!type.trim()) return { error: "Type is missing!" };

    // validation for genres
    // if genre is an array or not
    if (!genres.length) return { error: "Genres are missing!" };
    // checking if genres is filed with string value
    for (let gen of genres) {
        if (!gen.trim()) return { error: "Invalid genres!" };
    }

    // validation for tags same as for genres
    if (!tags.length) return { error: "Tags are missing!" };
    for (let tag of tags) {
        if (!tag.trim()) return { error: "Invalid Tags!" };
    }

    // validation for cast same as for genres
    if (!cast.length) return { error: "Cast are missing!" };
    // checking if cast is an object
    for (let c of cast) {
        if (typeof c !== "object") return { error: "Invalid Cast!" };
    }

    return { error: null };
};
