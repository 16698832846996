import { useEffect, useState } from "react";
import { deleteMovie, getMovieForUpdate, getMovies } from "../api/movie";
import { useMovies, useNotification } from "../hooks";
import MovieListItem from "./MovieListItem";
import ConfirmModal from "./modals/ConfirmModal";
import UpdateMovie from "./modals/UpdateMovie";

const pageNo = 0;
const limit = 5;

const LatestUploads = () => {
    // const [movies, setMovies] = useState([]);
    // const [busy, setBusy] = useState(false);
    // const [showUpdateModal, setShowUpdateModal] = useState(false);
    // const [showConfirmModal, setShowConfirmModal] = useState(false);
    // const [selectedMovie, setSelectedMovie] = useState(null);

    // const { updateNotification } = useNotification();
    const { fetchLatestUploads, latestUploads } = useMovies();

    // const fetchLatestUploads = async () => {
    //     const { error, movies } = await getMovies(pageNo, limit);

    //     if (error) return updateNotification("error", error);

    //     setMovies([...movies]);
    // };

    // const handleOnEditClick = async ({ id }) => {
    //     const { movie, error } = await getMovieForUpdate(id);
    //     setShowUpdateModal(true);

    //     if (error) return updateNotification("error", error);

    //     setSelectedMovie(movie);
    // };

    // const handleOnDeleteClick = (movie) => {
    //     setSelectedMovie(movie);
    //     setShowConfirmModal(true);
    // };

    // const handleOnDeleteConfirm = async () => {
    //     setBusy(true);
    //     const { error, message } = await deleteMovie(selectedMovie.id);
    //     setBusy(false);

    //     if (error) updateNotification("error", error);

    //     updateNotification("success", message);
    //     fetchLatestUploads();
    //     hideConfirmModal();
    // };

    // const hideConfirmModal = () => {
    //     setShowConfirmModal(false);
    // };

    // const hideUpdateModal = () => {
    //     setShowUpdateModal(false);
    // };

    // const handleOnUpdate = (movie) => {
    //     const updatedMovies = movies.map((m) => {
    //         if (m.id === movie.id) return movie;
    //         return m;
    //     });

    //     setMovies([...updatedMovies]);
    // };

    const handleUIUpdate = () => {
        fetchLatestUploads();
    };

    useEffect(() => {
        fetchLatestUploads();
    }, []);

    return (
        <>
            <div className="bg-white shadow dark:shadow dark:bg-secondary p-5 rounded col-span-2">
                <h1 className="font-semibold text-2xl mb-2 text-primary dark:text-white">
                    Recent Uploads
                </h1>

                <div className="space-y-3">
                    {latestUploads.map((movie) => {
                        return (
                            <MovieListItem
                                key={movie.id}
                                movie={movie}
                                afterDelete={handleUIUpdate}
                                afterUpdate={handleUIUpdate}
                                // onDeleteClick={() => handleOnDeleteClick(movie)}
                                // onEditClick={() => handleOnEditClick(movie)}
                            />
                        );
                    })}
                </div>
            </div>

            {/* <ConfirmModal
                title="Are you sure?"
                subTitle="This action will remove this movie permanently."
                visible={showConfirmModal}
                onConfirm={handleOnDeleteConfirm}
                onCancel={hideConfirmModal}
                busy={busy}
            />

            <UpdateMovie
                visible={showUpdateModal}
                initialState={selectedMovie}
                onSuccess={handleOnUpdate}
                onClose={hideUpdateModal}
            /> */}
        </>
    );
};

export default LatestUploads;
