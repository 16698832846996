import { useState } from "react";
import { updateReview } from "../../api/review";
import { useNotification } from "../../hooks";
import RatingForm from "../form/RatingForm";
import ModalContainer from "./ModalContainer";

const EditRatingModal = ({ visible, initialState, onClose, onSuccess }) => {
    const [busy, setBusy] = useState(false);

    const { updateNotification } = useNotification();

    const handleSubmit = async (data) => {
        setBusy(true);
        const { error, message } = await updateReview(initialState.id, data);
        setBusy(false);
        if (error) return updateNotification("error", error);

        onSuccess({ ...data });
        updateNotification("success", message);
        onClose();
    };

    return (
        <ModalContainer visible={visible} onClose={onClose} ignoreContainer>
            <RatingForm
                busy={busy}
                initialState={initialState}
                onSubmit={handleSubmit}
            />
        </ModalContainer>
    );
};

export default EditRatingModal;
