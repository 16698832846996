import Container from "./Container";
import HeroSlideshow from "./user/HeroSlideshow";
import NotVerified from "./user/NotVerified";
import TopRatedMovies from "./user/TopRatedMovies";
import TopRatedTvSeries from "./user/TopRatedTvSeries";
import TopRatedWebSeries from "./user/TopRatedWebSeries";

const Home = () => {
    return (
        <div className="dark:bg-primary bg-white min-h-screen">
            <Container className="px-2 xl:p-0">
                <NotVerified />
                {/* slider */}
                <HeroSlideshow />
                {/* Most rated */}
                <div className="space-y-3 py-8">
                    <TopRatedMovies />
                    <TopRatedWebSeries />
                    <TopRatedTvSeries />
                </div>
            </Container>
        </div>
    );
};

export default Home;
