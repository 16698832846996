export const isValidEmail = (email) => {
    const validEmailRegex = /^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return validEmailRegex.test(email);
};

export const getToken = () => localStorage.getItem("auth-token");

export const catchError = (error) => {
    const { response } = error;
    if (response?.data) return response.data;

    return { error: error.message || error };
};

export const renderItem = (result) => {
    return (
        <div key={result.id} className="flex rounded overflow-hidden">
            <img
                src={result.avatar}
                alt={result.name}
                className="w-16 h-16 object-cover"
            />
            <p className="dark:text-white font-semibold">{result.name}</p>
        </div>
    );
};

export const getPoster = (posters = []) => {
    const { length } = posters;

    if (!length) return null;

    // If there is more than 2 responsive poster then selecting second one.
    if (length > 2) return posters[1];

    // Else the first one
    return posters[0];
};

export const convertReviewCount = (count = 0) => {
    if (count <= 999) return count;

    return parseFloat(count / 1000).toFixed(1) + "k";
};
