import Container from "../Container";
import Title from "../form/Title";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import CustomLink from "../CustomLink";
import { commonModalClasses } from "../../utils/theme";
import FormContainer from "../form/FormContainer";
import { useEffect, useState } from "react";
import { createUser } from "../../api/auth";
import { useNavigate } from "react-router-dom";
import { useAuth, useNotification } from "../../hooks";
import { isValidEmail } from "../../utils/helper";

const validateUserInfo = ({ name, email, password }) => {
    const validNameRegex = /^[a-z A-Z]+$/;

    if (!name.trim()) return { ok: false, error: "Name is missing!" };
    if (!validNameRegex.test(name))
        return { ok: false, error: "Invalid name!" };

    if (!email.trim()) return { ok: false, error: "Email is missing!" };
    if (!isValidEmail(email)) return { ok: false, error: "Invalid email" };

    if (!password.trim()) return { ok: false, error: "Password is missing!" };
    if (password.length < 8)
        return {
            ok: false,
            error: "Password must be 8 or more characters long.",
        };

    return { ok: true };
};

const Signup = () => {
    const [userInfo, setUserInfo] = useState({
        name: "",
        email: "",
        password: "",
    });

    const navigate = useNavigate();
    const { authInfo } = useAuth();
    const { isLoggedIn } = authInfo;

    const { updateNotification } = useNotification();

    const handleChange = ({ target }) => {
        const { value, name, password } = target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { ok, error } = validateUserInfo(userInfo);

        if (!ok) return updateNotification("error", error);

        const response = await createUser(userInfo);
        if (response.error) return console.log(response.error);

        navigate("/auth/verification", {
            state: { user: response.user },
            replace: true,
        });

        console.log(response.user);
    };

    useEffect(() => {
        if (isLoggedIn) navigate("/");
    }, [isLoggedIn]);

    const { name, email, password } = userInfo;

    return (
        <FormContainer>
            <Container>
                <form
                    onSubmit={handleSubmit}
                    className={commonModalClasses + " w-72"}
                >
                    <Title>Sign Up</Title>
                    <FormInput
                        value={name}
                        label="Name"
                        placeholder="John Doe"
                        name="name"
                        onChange={handleChange}
                    />
                    <FormInput
                        value={email}
                        label="Email"
                        placeholder="john@email.com"
                        name="email"
                        onChange={handleChange}
                    />
                    <FormInput
                        value={password}
                        type="password"
                        label="Password"
                        placeholder="********"
                        name="password"
                        onChange={handleChange}
                    />
                    <Submit value="Sign up" />

                    <div className="flex justify-between">
                        <CustomLink to="/auth/forget-password">
                            Forget Password?
                        </CustomLink>
                        <CustomLink to="/auth/signin">Sign in</CustomLink>
                    </div>
                </form>
            </Container>
        </FormContainer>
    );
};

export default Signup;
