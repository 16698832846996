import { BsFillSunFill } from "react-icons/bs";
import Container from "../Container";
import { Link, useNavigate } from "react-router-dom";
import { useAuth, useTheme } from "../../hooks";
import AppSearchForm from "../form/AppSearchForm";

const Navbar = () => {
    const { toggleTheme } = useTheme();
    const { authInfo, handleLogout } = useAuth();
    const { isLoggedIn } = authInfo;

    const navigate = useNavigate();

    const handleSearchSubmit = (query) => {
        navigate("/movie/search?title=" + query);
    };

    return (
        <div className="shadow-sm bg-secondary shadow-gray-500">
            <Container className="p-2">
                <div className="flex items-center justify-between">
                    <Link to="/">
                        <img src="./logo.png" alt="" className="sm:h-10 h-8" />
                    </Link>

                    <ul className="flex items-center sm:space-x-4 space-x-2">
                        <li>
                            <button
                                className="p-1 rounded dark:bg-white bg-dark-subtle sm:text-2xl text-lg"
                                onClick={toggleTheme}
                            >
                                <BsFillSunFill className="text-secondary" />
                            </button>
                        </li>
                        <li>
                            <AppSearchForm
                                placeholder="Search"
                                inputClassName="text-dark-subtle text-white focus:border-white sm:w-auto w-40 sm:text-lg"
                                onSubmit={handleSearchSubmit}
                            />
                        </li>
                        <li>
                            {isLoggedIn ? (
                                <button
                                    onClick={handleLogout}
                                    className="text-lg font-semibold text-white"
                                >
                                    Log Out
                                </button>
                            ) : (
                                <Link
                                    to="/auth/signin"
                                    className="text-lg font-semibold text-white"
                                >
                                    Login
                                </Link>
                            )}
                        </li>
                    </ul>
                </div>
            </Container>
        </div>
    );
};

export default Navbar;
