import Container from "../Container";
import Title from "../form/Title";
import FormInput from "../form/FormInput";
import Submit from "../form/Submit";
import CustomLink from "../CustomLink";
import { commonModalClasses } from "../../utils/theme";
import FormContainer from "../form/FormContainer";
import { useEffect, useState } from "react";
import { useAuth, useNotification } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../../utils/helper";

const validateUserInfo = ({ email, password }) => {
    if (!email.trim()) return { ok: false, error: "Email is missing!" };
    if (!isValidEmail(email)) return { ok: false, error: "Invalid email" };

    if (!password.trim()) return { ok: false, error: "Password is missing!" };
    if (password.length < 8)
        return {
            ok: false,
            error: "Password must be 8 or more characters long.",
        };

    return { ok: true };
};

const Signin = () => {
    const [userInfo, setUserInfo] = useState({
        email: "",
        password: "",
    });

    const navigate = useNavigate();

    const { updateNotification } = useNotification();
    const { handleLogin, authInfo } = useAuth();
    const { isPending, isLoggedIn } = authInfo;

    const handleChange = ({ target }) => {
        const { value, name, password } = target;
        setUserInfo({ ...userInfo, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const { ok, error } = validateUserInfo(userInfo);

        if (!ok) return updateNotification("error", error);

        handleLogin(userInfo.email, userInfo.password);
    };

    // useEffect(() => {
    //     if (isLoggedIn) navigate("/");
    // }, [isLoggedIn]);

    return (
        <FormContainer>
            <Container>
                <form
                    onSubmit={handleSubmit}
                    className={commonModalClasses + " w-72"}
                >
                    <Title>Sign In</Title>
                    <FormInput
                        name="email"
                        label="Email"
                        placeholder="john@email.com"
                        value={userInfo.email}
                        onChange={handleChange}
                    />
                    <FormInput
                        type="password"
                        name="password"
                        label="Password"
                        placeholder="********"
                        value={userInfo.password}
                        onChange={handleChange}
                    />
                    <Submit value="Sign in" busy={isPending} />

                    <div className="flex justify-between">
                        <CustomLink to="/auth/forget-password">
                            Forget Password?
                        </CustomLink>
                        <CustomLink to="/auth/signup">Sign up</CustomLink>
                    </div>
                </form>
            </Container>
        </FormContainer>
    );
};

export default Signin;
