const FormInput = ({ name, label, placeholder, ...rest }) => {
    return (
        <div className="flex flex-col-reverse">
            <input
                id={name}
                name={name}
                className="w-full p-1 text-lg dark:text-white transition bg-transparent border-2 rounded outline-none dark:border-dark-subtle border-light-subtle focus:border-primary dark:focus:border-white peer"
                placeholder={placeholder}
                {...rest}
            />
            <label
                className="self-start font-semibold transition text-light-subtle dark:text-dark-subtle dark:peer-focus:text-white peer-focus:text-primary"
                htmlFor={name}
            >
                {label}
            </label>
        </div>
    );
};

export default FormInput;
