import { Link } from "react-router-dom";

const CustomLink = ({ to, children }) => {
    return (
        <Link
            className="text-light-subtle dark:text-dark-subtle hover:text-primary dark:hover:text-white transition"
            to={to}
        >
            {children}
        </Link>
    );
};

export default CustomLink;
