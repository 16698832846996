const CustomButtonLink = ({ label, onClick, clickable = true }) => {
    const className = clickable
        ? "text-highlight dark:text-highlight-dark hover:underline"
        : "text-highlight dark:text-highlight-dark cursor-default";

    return (
        <button onClick={onClick} className={className} type="button">
            {label}
        </button>
    );
};

export default CustomButtonLink;
